import homeImg from 'assets/pictures/A_Home.png';
import worldImg from 'assets/pictures/Best_2_Worlds.png';
import bgGradient from 'assets/pictures/bg_gradient_lights.png';
import featureCard from 'assets/pictures/feature_card.png';
import featureChat from 'assets/pictures/feature_chat.png';
import featureCollection from 'assets/pictures/feature_collection.png';
import featureFreeze from 'assets/pictures/feature_freeze.png';
import featureMobile from 'assets/pictures/feature_mobile.png';
import featurePicker from 'assets/pictures/feature_picker.png';
import featureReport from 'assets/pictures/feature_report.png';
import highligherImg from 'assets/pictures/highlighter.png';
import aiImg from 'assets/pictures/Integ_AIchat.png';
import poweredAiImg from 'assets/pictures/powered_ai.png';
import stickyNotesImg from 'assets/pictures/stickynotes.png';
import systemImg from 'assets/pictures/system_ow_01.png';
import tagsRecipesImg from 'assets/pictures/tags_recipes.png';
import timefreezeImg from 'assets/pictures/timefreeze.png';
import wave2 from 'assets/pictures/wave_02.svg';
import wave3 from 'assets/pictures/wave_03.svg';
import wave4 from 'assets/pictures/wave_04.svg';
import workspace from 'assets/pictures/workspace.png';
// In seconds
export const SEND_EMAIL_TIMEOUT = 120;

// In ms
export const CHECK_SUBCRIBE_INTERVAL = 900000;

export const CHECK_SUBSCRIBE_EXPIRATION = 1000 * 60 * 60 * 24;

export const GOOGLE_PLAY_APP_LINK =
  'https://play.google.com/store/apps/details?id=net.mypicker.mobile';

export const APP_STORE_APP_LINK =
  'https://apps.apple.com/us/app/picker-work-smarter/id6475775966';

export const CHROME_EXTENSION_APP_LINK =
  'https://chromewebstore.google.com/detail/picker/ehmncfpmldonfdpcialnplmdjpecfiho';

export const fontArray = [
  {
    value: 'Arvo',
    label: 'Arvo',
  },
  {
    value: 'Crimson',
    label: 'CrimsonText',
  },
  {
    value: 'Literata',
    label: 'Literata',
  },
  {
    value: 'Merriweather',
    label: 'Merriweather',
  },
  {
    value: 'Karla',
    label: 'Karla',
  },
  {
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    value: 'OpenSans',
    label: 'OpenSans',
  },
  {
    value: 'Roboto',
    label: 'Roboto',
  },
];

//For react-select to play nice with MUI dark/light theme
export const getSelectTheme = (theme) => {
  return {
    /*
     * multiValue(remove)/color:hover
     */
    danger: 'black',

    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: 'pink',

    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: theme.palette.background.paper,

    /*
     * control/backgroundColor(disabled)
     */
    neutral5: 'var(--neutral-5)',

    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: theme.palette.reactselect.select,

    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: 'var(--neutral-20)',

    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: 'var(--neutral-30)',

    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: 'var(--neutral-40)',

    /*
     * placeholder/color
     */
    neutral50: 'var(--neutral-50)',

    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: 'var(--neutral-60)',

    neutral70: 'var(--neutral-70)',

    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: 'var(--neutral-80)',

    neutral90: 'var(--neutral-90)',

    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: 'var(--primary)',

    /*
     * option/backgroundColor(focused)
     */
    primary25: theme.palette.reactselect.primary,

    /*
     * option/backgroundColor:active
     */
    primary50: theme.palette.reactselect.primary75,

    primary75: 'var(--primary-75)',
  };
};

export const helpAndSupportItems = [
  {
    title: 'Getting started',
    text: [
      { header: 'Welcome to Picker!' },
      `Say goodbye to digital clutter and hello to a sleek and organized digital workspace. We can't wait for you to try it out and discover how Picker can 
      help you be more productivity.`,
      `So come on and let's get started!`,
      { header: 'First, Sign up' },
      'You can sign up for Picker with a Google or Apple account our just using your email.',
      '1. Tap on the Log In button.',
      '2. Select how you want to sign-up, if you choose email - enter your email and create a password.',
      '3. Click the button “Sign up” once you have filled in the required information.',
      '4. You will receive a confirmation email from Picker to verify your account, so just follow the instructions.',
      { header: 'Then, Add extension (Your Surfer Buddy)' },
      '1. Click on “Get Picker for Chrome”',
      '2. You will be redirected to a Download page on Chrome Web Store',
      '3. Click download and follow the instructions',
      `4. Once the extension is installed you can also "pin it" to the extension section in the upper right corner of your chrome browser.`,
      `Now Picker is installed and you can start using both the Surfer Buddy and access your Private Workspace on mypicker.net.`,
      { header: 'Lastly, the Mobile app' },
      'Your Mobile version is available for download on both Apple App Store and Google Play Store.',
      '1. Go to App Store (for iOS devices) or Google Play Store for Android devices) on your mobile.',
      '2. Search for "Picker – work smarter" in the App section of the store.',
      '3. Then click “Download” and follow the instructions',
      '4. Use the same Log-in as you used when you signed up for Picker.',
      'Now you’re all set and can also use Picker on the go and benefit from its features and tools directly from your mobile!',
    ],
    tutorialUrl:
      'https://www.youtube.com/embed/8OPE--YbDsg?si=mpTlMIe73xTX-aEe&autoplay=0',
  },
  {
    title: 'Using Picker',
    text: [
      `Welcome to Picker, a great way to level up your productivity, it’s designed to enhance
your internet experience and make it easy to interact with pages across the web.
Picker is a productivity app that assists users to keep track of ideas, documents, and
pictures in a personal workplace. It also offers features like collaboration, and
search capabilities to help users stay structured.
The key values of Picker lies in its abilities to improve efficiency, streamline workflow,
and provide a central place for content that matters, making it a useful tool for both personal and
professional use.
Picker allows users to create collections, highlight important information, and add
tags to keep everything organized and easy to find. In addition, Picker even has unique capabilities
such as Time Freeze, were you can save a webpage frozen in time, and Read Mode.
Picker is designed to appeal to people from all walks of life and backgrounds.
Whether you're a college student looking for a great study tool or a foodie gathering recipes
for a big birthday party or a TV producer planning the episode for your upcoming TV series.
Picker has all the features you need to make the most out of every moment. Get ready to take your
surfing game to the next level with Picker.`,
      { header: 'Surfer Buddy' },
      `Meet your Surfer Buddy! The Surfer Buddy tool is designed to make your life easier
and more efficient when interacting with webpages. With its user-friendly interface and practical
design you can easily move the Surfer Buddy around and position it wherever you want with just a
simple Drag & Drop. This allows you to have full control over where the tool is placed, making it
convenient for you to access at all times.`,
      { header: 'Buddy Mode' },
      `By default, your extension will be tucked away in the upper left corner of your Chrome
browser in Buddy Mode, with its tools contracted. You can also hide it by holding down Alt and
clicking, and bring it back by Alt-clicking again.
Simply click on it to switch to Work Mode and access all picker tools.
You can also customize the appearance of your Surfer Buddy by going to Settings (the last circle
when you are in work mode) and change the Surfer Buddy symbol to any images of your choosing.`,
      { header: 'Work Mode' },
      `Work Mode makes the process of gathering and managing information easy.
You have instant access to the most necessary tools for streamlining your workflow,
with AI chat, create and save to Collections, Sticky Notes, Highlighters, Tags and Read Mode.
All your interactions will be saved and stored at your Personal workspace on mypicker.net`,
      { header: 'My Personal workspace' },
      `Your personal workspace on mypicker.net is where all your picks and interactions are saved and
stored. A place reserved for the things that hold meaning and importance to you. This is the home for
all your collections, work related collections surrounded by collections that bring you joy and
inspiration.
The Personal workspace is exclusively yours, complete with a
dashboard layout for efficiently organizing, storing, and utilizing all of your
selected items. This dashboard-style setup provides you with a convenient and
personalized way to manage all the pages and info you are collecting.
Each of your chosen items is presented as a Page card within its

designated collection, with quick-view you can access all key info from each page.
With Live Collection you can share an entire collection in real time with all your notes, highlights
and sources – by just flicking a switch on the Collection card. The people you have shared the
Collection with can then follow your work with that Collection.`,
      { header: 'Picker on the go!' },
      `The Picker mobile app is also connected to your Personal workspace and allows you to
also use your phone to create collections, save and interact with content on webpages,
making it a versatile tool for note-taking and productivity.`,
    ],
  },
  {
    title: 'Account & Billing',
    text: ['This section is not a part of the BETA test.'],
  },
  {
    title: 'Enterprise or Educational Institution',
    text: ['This section is not a part of the BETA test.'],
  },
  {
    title: 'FAQ',
    text: [
      { header: 'What is Picker?' },
      `With the constant flow of information and distractions, it's easy to become overwhelmed and
lose focus. This is where Picker comes in. It’s a three-part real-time system made to make it
easy for people to be more productive and to stay inspired, informed and organized.`,
      `The first part is our Surfer Buddy, a small but powerful browser extension designed to
enhance your surfing experience by allowing you to seamlessly save, categorize, AI chat,
manage and interact with pages across the web.`,
      `The second part is your Personal workspace on mypicker.net. where you can organize, create
collections and reports, store and share your findings. Here you also have quick and easy
acces to our AI chat. In a nutshell – This is a place for everything that really matters to you.`,
      `The third part is the Mobile app that makes it easy for you to pick things that interest you on
the go. It also syncs up in real-time with your personal workspace so you can work
seamlessly between your devices.`,
      `Our users are at the heart of everything we do, and we want to make it easy for people to be
more productive so they can free up time and spend it on what truly matters to them.`,
      { header: 'Why should I use Picker?' },
      `We understand that time is precious, so in a fast-paced world where productivity is key, Picker is your
ultimate productivity companion. With its easy-to-use interface, seamlessly integrated AI, and the
ability to help you stay informed, inspired, and organized, Picker empowers you to achieve your goals
like never before. Maximize your efficiency, save time, and prioritize what truly matters.`,
      'It’s great for Students, Knowledge workers and of course People with passions and hobbies.',
      { header: 'Why did you create Picker?' },
      `Picker was born from a real need. In an era of increasing digital clutter, gathering information,
processing, and saving it to be able to access it quickly when you need it, can be a real nightmare.
After years of small and sometimes big frustrations, using different methods and apps, often too
complicated or too simple, we realized it was not our way of working – at all.`,
      `When talking to friends and colleagues we realized there were a lot of people sharing our frustrations
and need, so we developed Picker – your Surfer Buddy, to make it easy to become more productive.`,
      { header: 'Who uses Picker?' },
      `It is used by Students, Knowledge workers and of course People with passions and hobbies.`,
      `For Students it will make the study-process easier and provide a more personalized experience.`,
      `For Knowledge workers it’s an indispensable tool for saving time, improving productivity, and
empowering them to focus on high-value tasks.`,
      `For people with a hobby or passion it’s an easy way to stay inspired, informed and organized – and
saving the things you really care about in one place.`,
      { header: 'Why is Picker great for Students?' },
      `College and University students often face numerous academic tasks, including assignments,
projects, exams, and extracurricular activities. These tasks can be overwhelming, leading to
stress and disorganization. Picker is tailored to the specific needs of college and university
students and can help them becoming more effective when studying and maintain a better
work-life balance.`,
      { header: 'Why is Picker great for Knowledge workers?' },
      `Knowledge workers already rely heavily on technology to streamline their work and enhance
productivity. They are accustomed to using various digital tools and platforms to manage
their tasks, communication, and project collaboration. Picker’s ability to reduce pain points in
existing workflows and help to improve productivity and free up time to spent on more high-
value tasks.`,
      { header: 'Why is Picker great for People with hobbies & passions?' },
      `Hobbies are often fueled by inspiration and creativity. Picker helps users to capture ideas, and
resources related to specific hobbies, find inspiration, explore new techniques or trends, and
keep their creative juices flowing. Picker unique user-interface and seamlessly integrated AI
will make it a valuable companion for individuals pursuing their passions and hobbies.`,
      `Picker offers unique opportunities for this diverse user base, addressing needs related to
organization and time management, fostering inspiration and creativity, facilitating
community and collaboration, and developing personal growth.`,
      { header: 'What makes Picker unique?' },
      `What sets Picker apart is our innovative user-interface combined with the seamless integration of AI
chat to all parts of Picker.`,
      `The floating “Surfer-Buddy” provides quick and easy access to the most necessary tools including for
interacting with webpages. Making it easy to become more efficient, save time, and prioritize what
truly matters.`,
      `The three-part real time system makes it a great way for the many people to level up their productivity
– especially Students, Knowledge workers and people working with volumes of information can
really streamline their work processes and get more done.`,
      { header: 'Is Picker complicated to use?' },
      `We sure hope it’s not. We spent an enormous amount and focus of just making the user exeprinces
as smooth and intuitive as possible. Our goal has been to make it as easy as possible to onboard,
understand and to use. It should take 1 minute to learn and 5 to master.`,
      { header: 'Who is behind Picker?' },
      `Picker was born in Sweden and raised in the US. Today we’re a lean start-up with our 100% remote
team spread over US, Asia, and Europe. Our focus is to develop easy to use solutions and new
innovations for the many people – empowering people and organizations to work smarter, level up
their productivity and free up time.`,
      { header: 'What is Time Freeze?' },
      `From Read mode, when we have cleaned up and sanitized the content on the webpage from annoying
Sidebars and disturbing ads you will have the option to Freeze the page in time. No matter if it’s
updated, edited or deleted you will still have access to it. It’s basically a way to Super-save a cleaned
up webpage.`,
      { header: 'What is a Live Collection?' },
      `It’s a way for you to share an entire Collection in real-time with all your Notes, Highlights, and Tags.
Whenever you make edits, add or remove things within the Collection the people you have shared it
with will see what you are doing in real time.`,
      { header: 'Will Picker send me content suggestion?' },
      `No, we do not suggest or pitch content to you. We have no predictive algorithms feeding you curated
content based on how you are interacting with web content.`,
      { header: 'Is Picker a Social Network or Social tool?' },
      `No, we no social ambitions. No likes, thumbs up or down. No profile pages.
No public boards or story flows.`,
      { header: 'ACCOUNT' },
      { header: 'Do I need to create an account to use the app?' },
      `Yes. Under the category “Getting Started” you can find detailed information on how to download the
app on computer and mobile devices.`,
      { header: 'Is Picker free?' },
      `Yes and no. Yes, you can test Picker for a period of 60 days and really take it for a spin and
see if it meets your expectations. And no, after the 60 days you can then eithet walk away or
start a subscription for $ 2.95 per month when payed annually – or as we say for less the price
of Tall Latte you can level up your productivity.`,
      `Your subscription fee will cover the costs of servers, hosting, AI chat and of course also gives
us resources to continue our development of Picker and make it even better and versatile for
each update and new version. We’re not in it for a sprint but for the long run.`,
      { header: 'Can I use the app without an internet connection?' },
      `No, offline mode is not available for the moment. Although it is a function we wish to implement in
the future.`,
      { header: 'How do I get a new password?' },
      `To obtain a new password, simply click on the “Log In”button. Then, select the “Password” button.
This will lead you to a new page where you must enter your email address. Soon after, you will
receive an email with instructions for creating a new password. If you can’t find your reset email in
your mail inbox it might be in your junk-mail.`,
      { header: 'Can I delete my account?' },
      `Yes, please note that this action cannot be undone, and once your data is deleted, it cannot be
recovered. To permanently delete your Pocket account and all associated data follow these steps:`,
      '1. Log in to your picker account.',
      `2. Click on your profile icon/avatar in the top right corner
of the screen.`,
      `3. Select "Settings" from the dropdown menu.`,
      `4. In the "Account" tab, scroll down to find the "Delete
Account" section. Click on the "Delete Account" button.`,
      '5. You will be asked to confirm the account deletion.',
      `6. Read the information provided and understand that
deleting your account is permanent and cannot be
undone.`,
      '7. Enter your password to confirm the deletion.',
      `8. Click on the "Delete Account" or "Confirm" button.`,
      `9. Your picker account will be permanently deleted.`,
      { header: 'Where can I provide feedback?' },
      'We value your feedback and suggestions. You can contact us on hello@mypicker.net',
      { header: 'What are the requirements for using Picker?' },
      `To use Picker, you need to have a Google Chrome browser version 90 or later running on a Windows,
Mac OS X, or Linux operating system. This ensures that you have the atest security updates and
features to protect your data while using the app.`,
      { header: 'Can I sync my data with other devices or platforms?' },
      `Yes, you can sync your data with other devices or platforms as long as they support Google Chrome
browser version 90 or later. You can sign in to your account on different devices and access all your
saved links and articles seamlessly.`,
      { header: 'Can I share content with my friends and family?' },
      `Yes, you can share an entire collection by using the “Live Collections” sharing function on
mypicker.net. However, it is important to note that each user's data is stored separately, and they will
not have access to your content or personal information.`,
      { header: 'SECURITY' },
      { header: 'Is my personal information secure while using the app?' },
      `Yes, at Picker, we understand the importance of protecting your personal information. That is why we
take several measures to ensure the security of your data. Firstly, all data transmitted through the app
is encrypted, making it difficult for anyone to intercept or steal your information.`,
      {
        header:
          'What measures does Picker take to ensure the security of my data?',
      },
      `Picker utilizes several security features to protect user's data. This includes encrypting data in transit
and at rest, as well as regular security audits.. Additionally, Picker uses secureservers and follows
industry-standard protocols to prevent unauthorized access.`,
      { header: 'Can anyone access my notes without my permission?' },
      `No, your notes are private and are only accessible by you. Picker uses encryption to protect your data
from unauthorized access. Moreover, you can also enable two-way factor authentication to your
account.`,
      { header: 'Does Picker sell or share my data with any third parties?' },
      `No, your data is just that – your data. Picker does not sell or share your data with third parties. Your
data is stored on secure servers and is only visible to you and those with whom you choose to share
your notes.`,
      { header: 'Is Picker safe and secure?' },
      `Yes, we are following the highest industry standard for authentication, authorization, and data
validation. Your data is encrypted and stored in the cloud with 24/7 access.`,
      'DARK MODE',
      { header: 'What is dark mode and why is it important?' },
      `Dark mode, also known as night mode or dark theme, is a setting that changes the color palette of an
app from light to dark. It essentially inverts the colors, making the background darker and the text and
images lighter. This feature is important because it can reduce eye strain, especially in low light
conditions. It also conserves battery life’s darker colors require less energy for display.`,
      { header: 'Does dark mode improve readability?' },
      `This question is often raised by users who are used to reading black text on a white background.
While some users find dark mode easier to read, others may prefer the traditional light mode. It
ultimately depends on personal preference and the lighting conditions in which the app is being used.
Some studies have shown that dark mode mayebe more beneficial for reading long blocks of text, as it
reduces the contrast between the background and text, making it less straining on the eyes.`,
      { header: 'Can dark mode help with night-time usage?' },
      `Yes, dark mode can be very beneficial for night-time usage. The bright, white backgrounds of apps
can be harsh on the eyes, especially in a dark environment. Dark mode can mitigate this issue by
reducing the amount of light emitted from the screen. This can also help with better sleep, as the
bright light from screens can disrupt our natural sleep patterns.`,
    ],
    tutorialUrl:
      'https://www.youtube.com/embed/8OPE--YbDsg?si=mpTlMIe73xTX-aEe&autoplay=0',
  },
  {
    title: 'Subscription Information',
    text: [
      { header: 'Cancel your Subscription' },
      `To discontinue your Premium subscription, you just have to stop the automatic renewal of your plan. Uninstalling
      the app won't cancel your subscription. This can be accomplished by turning your subscription off through the
      payment method you used:`,
      { header: '- App Store (iOS)' },
      `App Store subscriptions renew automatically, meaning you have to cancel them yourself. To do this, go to your
      Apple ID in iTunes or the App Store, and choose 'Subscriptions' to find the option to cancel the auto-renewal of
      your Picker subscription.`,
      { header: '- Google Play Store (Android)' },
      `On your Android device, go to Google Play subscriptions, choose the Picker subscription you want to cancel, tap
      'Cancel subscription,' and then follow the instructions. `,
    ],
  },
];

export const descriptionSections = [
  {
    img: worldImg,
    title: 'The best of two worlds',
    text: 'We combine traditional tools like <strong>Highlighters</strong> and <strong>Sticky Notes</strong> with <strong>AI chat</strong> for seamless extraction, collection, and utilization of online information.',
    bgImg: wave2,
  },
  {
    img: aiImg,
    title: 'Integrated AI chat',
    text: 'Get instant answers, explore topics further, and <strong>save valuable informationc</strong> with our AI chat feature <strong>directly to your Personal Workspace.</strong>',
  },
  {
    img: homeImg,
    title: 'A home for all your picks',
    text: '<strong>Safe and secure</strong>, all info gets saved to your Personal Workspace.Where you can continue to work with it or share it with peers, friends or family. <strong>It’s your data – you decide.</strong>',
    bgImg: wave2,
    withBtn: true,
  },
];

export const recipesDescriptionSections = (isLowerLg = false) => {
  let tag = '</br>';

  if (isLowerLg) {
    tag = '';
  }

  return [
    {
      img: systemImg,
      text: '<strong>Born from the family cookbook idea</strong> with recipes collected over time and sticky notes falling out. Scribbles on every page to remember tweaks and adjusting measurements to <strong>make the recipe yours.</strong> We added AI chat so you can get instant access to invaluable help.<br/><br/><strong>A new home for all your recipes.<strong/>',
      imgTitle: 'Turn online recipes into your own digital cookbook',
      underText:
        'All recipes you collect with our chrome extension and/or apps are instantly saved to your private collections on mypicker.net',
    },
    {
      img: highligherImg,
      title: 'Great highlighters',
      text: `Navigate the recipe easier with highlights.${tag} 
           Mark what’s important in the recipe.${tag} 
           Choose from 4 colors and color co-ordinate${tag} 
           with your sticky notes.`,
      bgImg: bgGradient,
    },
    {
      img: stickyNotesImg,
      title: 'The Stickiest Notes',
      text: 'Use sticky notes to remember tweaks to measurements, swapping of ingredients and jot down comments - make each dish yours.',
      textColor: 'mainPage',
    },
    {
      img: poweredAiImg,
      title: 'Powered up with AI chat',
      text: `Our integrated AI is a foodie's little helper.${tag} 
           Chat, ask questions without leaving the page,${tag} 
           get ideas for sides that complement your${tag} 
           main dish. Ask for wine and beverage pairings.${tag} 
           Ask AI where you can buy specialty ingredients${tag} 
           in your region or what local produce is in season${tag} 
           for you right now.`,
      bgImg: bgGradient,
    },
    {
      img: tagsRecipesImg,
      title: 'Tags',
      text: `Quickly locate saved items by tagging them.${tag} 
           As your digital cookbook expands, you can${tag} 
           categorize recipes with tags and organize it${tag} 
           your way. Whether you choose labels like${tag} 
           Delicious, Slow Cooker, typ of Cuisine, Meal,${tag} 
           or Main ingredient the choice is yours.`,
    },
    {
      img: timefreezeImg,
      title: 'Super Save with Time Freeze',
      text: 'Never lose a recipe - save it with time freeze and you’ll always have access to it even if it’s changed or removed. As a bonus, this also helps you get rid of annoying ads and disruptive sidebars.',
      bgImg: bgGradient,
    },
  ];
};

export const promisesItems = [
  {
    title: 'No ads',
    text: ['For subscribes - Picker is ad free'],
  },
  {
    title: 'No pitching or curating',
    text: [
      'We do not suggest or pitch content to you.',
      'No predective algorithms feeding you currated content.',
    ],
  },
  {
    title: 'No social ambitions',
    text: [
      'No likes, no thumbs up or down. No profile pages.',
      'No public boards or story flows.',
    ],
  },
  {
    title: 'Your data is yours',
    text: [
      'We do not share, sell or claim ownership of any of your data.',
      'You decide and control what you want to share and with whom.',
    ],
  },
  {
    title: 'Safe and secure',
    text: [
      'Highest industry standard for authentication, authorization and data validation. Your data is encrypted and stored on the cloud with 24/7 access.',
    ],
  },
  {
    title: 'Price',
    text: [
      'For less than the price of a ”Tall Latte” $4.99 per month, you can level up your productivity',
    ],
  },
];

export const faqItems = [
  {
    title: 'What is Picker?',
    text: [
      `Picker makes it easy for you to Pick info you find online and save it to one place so you can stay inspired, informed and organized.`,
      `Picker has three parts.`,
      `1) your personal workspace - a place where you can create your own Collections and work with all your online picks.`,
      `2) Your Surfer Buddy a productivity Companion (Google Chrome extension) that neatly floats on top of basically all webpages. You use it to process information by asking questions to AI, highlighting important information and jotting down thoughts on sticky notes. This is where most of our “old school” tools exist. The ones you’ve used in school. Just don't go highlighter happy, or do!`,
      `3) Lastly, our mobile app which allows you to ask questions to our AI, take notes and add webpages to your collections. A butterfly net, you use to Pick things on the go.`,
      `At its core Picker is a productivity tool to make research easy but it's applicable to many scenarios. Continue reading to find out how you can use Picker.`,
    ],
  },
  {
    title: 'Who uses Picker?',
    text: [
      `Picker is awesome for everyone.`,
      `It really doesn't matter what you do online. It’s an amazing time saver and very practical for students, or content creators, market researchers and journalists but it’s very useful for everyone. If you’re into traveling you can create a collection for what you want to do on your trip. You can create shopping lists or wedding inspiration. You can save tutorials on new skills you are trying to master. Create your own cookbook with the recipes you find online. You can save news articles and have them easily accessible. Save all your favorite properties when house hunting. The possibilities are endless!`,
      `We like to say Picker is great for students, knowledge workers and people with passions & hobbies.`,
    ],
  },
  {
    title: 'What makes Picker unique?',
    text: [
      `A few things actually!`,
      `-${'   '}Our floating Surfer Buddy`,
      `-${'   '}Real-time sharing with “Live Collection”`,
      `-${'   '}Integrated AI that allows you to save your answers to collections`,
      `-${'   '}Floating sticky notes that stick to the page`,
      `-${'   '}Readability mode, choose font, its color and size, and change the background color`,
      `-${'   '}Extract key info, compiles all your notes, highlights and sources within a collection into a practical pdf`,
      `-${'   '}Time freeze, save the information on a page as it is, so if it changes later you still have the same version`,
      `-${'   '}Personalization, change collection images, page images, select your own Surfer Buddy icon, color on sticky notes & highlights`,
    ],
  },
  {
    title: 'Is Picker free?',
    text: [
      `For your first 60 days, yes!`,
      `After your 60 day test spin you will be asked if you want to continue using Picker for a sum of $4.99 per month or $49.90 for a yearly subscription. So for less than the price of a tall latte you get a full month of Picker.`,
      `The subscription fee goes to cover server costs, hosting, AI chat and developing to make sure Picker stays the best online productivity tool, in our opinion at least.`,
    ],
  },
  {
    title: 'Is Picker safe and secure?',
    text: [
      `Yes, we follow the highest industry standards for authentication, authorization and data validation. Your data is encrypted and stored in the cloud with 24/7 access.`,
    ],
  },
];

export const offerDescriptionList = [
  {
    title: 'Try for free – 60 days',
    desciption: 'No bank or credit card required',
  },
  {
    title: 'Cancel at any time',
    desciption: 'Just stop using Picker. No strings attached.',
  },
  {
    title: 'After your 60 days free trial',
    desciption:
      'You can then choose a monthly ($4.99) or yearly ($49.90) plan.',
  },
];

export const supportingBlock = [
  {
    title: 'A butterfly net',
    description: 'Pick info easily',
    isMobile: true,
    isDesktop: false,
  },
  { title: 'Surfer Buddy', description: 'Floating tool-kit', isDesktop: true },
  {
    title: 'AI chat',
    description: 'Fully integrated',
    isMobile: true,
    isDesktop: true,
  },
  {
    title: 'Save AI answers',
    description: 'to Collections',
    isMobile: true,
    isDesktop: true,
  },
  {
    title: 'Live Collections',
    description: 'Real time sharing',
    isMobile: true,
    isDesktop: true,
  },
  { title: 'Read mode', description: 'No distractions', isDesktop: true },
  { title: 'Highlighters', description: 'Best there is', isDesktop: true },
  { title: 'Time freeze', description: 'Super save it', isDesktop: true },
  {
    title: 'Sticky Notes',
    description: 'Stays on pages',
    isMobile: true,
    isDesktop: true,
  },
  {
    title: 'Extract Key info',
    description: 'Info into pdf',
    isMobile: true,
    isDesktop: true,
  },
];

export const tryForFreeTestimonials = [
  {
    name: 'Greg',
    testimonial:
      'This not only great for the writers and producers.It can be used for everything You find online and want to keep.',
    company: 'Filmmaker',
  },
  {
    name: 'Jane',
    testimonial:
      'I use it every day both for my studies and probably more for my personal interests. I also used it when I helped my parents with their kitchen make- over',
    company: 'Student',
  },
  {
    name: 'Ivan',
    testimonial:
      'I save on average 50% of my time when gathering and processing info for my research when I’m doing market and competitive analysis.',
    company: 'Market researcher',
  },
];

export const mainTestimonials = [
  {
    name: 'Matilda',
    testimonial:
      "Picker has seamlessly integrated into my academic life, simplifying how I manage content for essays and exams. Every time I use it, I'm reminded of how much smoother my study process has become.",
    company: 'Lunds University / Sociologist Programe',
  },
  {
    name: 'Maria',
    testimonial:
      'This is great, I can use it to collect recipes and facts related to my work and I use it as an archive/catalog for my vinyl record collection.',
    company: 'Chef',
  },
  {
    name: 'Ivan',
    testimonial:
      'I save on average 50% of my time when gathering and processing info for my research when I’m doing market and competitive analysis.',
    company: 'Market researcher',
  },
];

export const personalWorkspaceList = [
  {
    title: 'Collections',
    text: [
      'You can choose to display them as most recently worked with or in alphabetical order. To the right you can see a small plus button, click the plus to create a new collection. The image you see is the default collection image and this will change when you make your first Pick or select a Collection image.Below that you see the collection name and how many pages are in that collection.',
    ],
  },
  {
    title: 'Sharing',
    text: [
      'By flipping the switch in the bottom left of a collection card you activate “Live collection”. This lets that specific collection be shared in real time with whomever you want. When you add a new note or make a change to your collection - the people you have shared it with will se your changes instantly.',
      'Press the share button next to the switch and select how you would like to share your Live Collection. If you ever change your mind and want to stop your collection from being live just flip the switch back to turn sharing off.',
    ],
  },
  {
    title: 'Extract Key info',
    text: [
      'This function compiles all your sources, highlights and notes from an entire Collection and packages it into a PDF. This is a time saver for when you are working with online information for work or school and you want quick access only to the information you already have processed.',
    ],
  },
  {
    title: 'Pages',
    text: [
      'By clicking on a collection you will see all the pages that are collected in it.',
      'Just like with the collection page you can view pages by most recent or in alphabetical order. Here you can also select to only see time freeze mode. The pictures are the page images. In the sources-circle you see the logo of the page which it derives from and the name of the page. Underneath that you have the quick view option. Just mouse over the icons to see what tags, sticky notes, and highlights you have on that specific page. In the three dot menu you can change the page title or delete the page.',
    ],
  },
  {
    title: 'Organize',
    text: [
      'With drag and drop you can move pages from one collection to another, or copy a page over so that it exists in both collections. Similarly if you want to copy a page over you option click on Mac and Alt click on PC. Next to the search bar you can select whether you want to work with page images or without. ',
    ],
  },
  {
    title: 'Tags',
    text: [
      'Here you can use the Tags that you created with your Surfer Buddy to find pages with similar topics even though they may reside in different collections. Select the tag or tags, and see which web pages you have saved.',
    ],
  },
  {
    title: 'Live Collections',
    text: [
      'This is the home for the Live Collections people have shared with you. Similar to Collections and Pages you can view by most recent or alphabetically. The three dot menu also lets you update the title or delete the live collection if you no longer want it. ',
    ],
  },
  {
    title: 'AI - chat',
    text: [
      'Quick and easy access to your AI - chat also from here. It is integrated in the same way as in your Surfer Buddy and Mobile app.and works in the same way.Once you typed in your question, just click “send”.',
      'To save an answer, select or create a new collection at the bottom of the window. Then click “Save answer” at the bottom of the answer.Then continue to chat or press “Start a new chat”.',
    ],
  },
];

export const personalizationList = [
  {
    title: 'Collection image',
    text: [
      'You can change the collection image by finding an image you’d like to use. With the correct collection selected, right click on the image and click set page image.',
    ],
  },
  {
    title: 'Page image',
    text: [
      'Setting the page image is a little different. Here you can only choose from most of the images on the page. However, the process is the same.',
    ],
  },
  {
    title: 'Surfer Buddy',
    text: [
      'Make your Surfer Buddy more - You! Change the image on your Surfer Buddy from the Picker icon by going into settings and click select image then upload the image you like instead (300x300px).',
    ],
  },
  {
    title: 'Blocked list',
    text: [
      'If you don’t want your Surfer Buddy to appear on a specific website you can easily choose to block your Surfer Buddy from appearing by flipping the block switch in while in the settings function of your Surfer Buddy when you are on the page you wish to block. Here you can also open and view the Blocked List and of course also remove a website from your Blocked list.',
    ],
  },
];

export const surfetBuddyList = [
  {
    title: 'AI - chat',
    text: [
      'After downloading The Surfer Buddy from your Desktop.',
      "Start by moving your Surfer Buddy around, it's easy, just click and drag.",
      'Place it where you want, then click on it to access your tools.',
      `At the top you have the integrated AI-chat. Once you type in your question, just click “send”.
      To save an answer, select or create a new collection at the bottom of the window. Then click
      “Save answer” at the bottom of the answer. Then continue to chat or press “Start a new chat”.`,
    ],
  },
  {
    title: 'Collection',
    text: [
      'If you have not created a collection your Picks will per default be saved to “Unsorted”.',
      'To create a collection start by typing the name you want for your collection and pressing the drop down button that displays below. When you have multiple collections this is also where you choose which you want to work with.',
      'Remember, it is important to have the correct collection selected when working with your Surfer Buddy.',
      'To work with your Picks within a collection go to your personal workspace on mypicker.net',
    ],
  },
  {
    title: 'Sticky Notes',
    text: [
      'To create a sticky note click the icon. At the top of the note you then choose from four colors, the option to minimize its size or to delete the sticky note. Click on the writing area to start writing. You can move your note around by dragging and dropping, they will stay there until you move them again.',
    ],
  },
  {
    title: 'Highlight',
    text: [
      'To highlight click the icon and pick the color you want. Then select the portion of the text you want to highlight. Make sure to click on the tool first and not after you have selected the text. To remove the highlight just repeat the same process and go over the same text again.',
    ],
  },
  {
    title: 'Tags',
    text: [
      'Create one or several tags for your Picks, so it is easier to find and organize them later. make it easier for you to find and sort relevant information.You can easily classify, Label, mark identification and belonging.',
    ],
  },
  {
    title: 'Read mode',
    text: [
      `Use this to get rid of distractions, annoying ads and sidebars. You can change the font, font size and background color on the page. Here you can also choose to save a page as “Time Freeze” which conserves the text website, so if the author makes any changes you will still have access to the version that you were working with. Note you can not put sticky notes or highlight when you are in readability mode.`,
    ],
  },
  {
    title: 'Settings',
    text: [
      'Here you can personalize your Surfer Buddy and also manage your Blocked list. The pages you don’t wany your Surfer Buddy to be present on.',
    ],
  },
];

export const mobileAppAccordionList = [
  {
    title: 'Collections',
    text: [
      'Once you have the correct app downloaded you can access all your collections and create new ones.',
    ],
  },
  {
    title: 'Sharing',
    text: [
      'You can turn on “live collection” and share your collection. Similar to your Personal Workspace.',
    ],
  },
  {
    title: 'Quick view and Notes',
    text: [
      'When you access a collection you can get a quick view of your tags, sticky notes and highlights by clicking on the icons.  Add a note to a page by clicking on the sticky note icon and then plus sign that is located to the right.',
    ],
  },
  {
    title: 'Adding a page',
    text: [
      'To add a page to a collection on the go, then click share and find the Picker app (The first few times it might be further down on the list of apps you have).',
    ],
  },
  {
    title: 'AI - chat',
    text: [
      'Quick and easy access to your AI - chat also from the mobile app. It is integrated in the same way as in your Surfer Buddy and your Personal workspace. It works in the same way, once you type in your question, just click “send”. To save an answer, select or create a new collection at the bottom of the window. Then click “Save answer” at the bottom of the answer.Then continue to chat or press “Start a new chat”.',
    ],
  },
  {
    title: 'Live Collections',
    text: [
      'Similar to your Personal Workspace you can view the “Live Collections” that have been shared with you. ',
    ],
  },
  {
    title: 'Account setting',
    text: [
      'Lastly you can access account settings. Edit your profile, change password and watch the mobile users guide among other things.',
    ],
  },
];

export const surferBoddySections = [
  {
    title: '2. Working with your Surfer Buddy',
    text: [
      'Great! Now that you have your Surfer Buddy installed it should appear when you go to a new page.',
      'Here is a short instructional video on how to use your Surfer Buddy or read about it below.',
    ],
    hints: surfetBuddyList,
    bgImage: bgGradient,
    iframeId: '6ARyWMYSjk4',
  },
  {
    title: '3. Your Personal workspace',
    text: [
      'Now back to your Personal Workspace! This is the main page where you can see And work with all your Picks and Collections.',
      'Here is a short instructional video on how to use your Personal workspace or you can read more about it below',
    ],
    hints: personalWorkspaceList,
    iframeId: 'NtU6BsW8Z-s',
  },
  {
    title: '4. Picker and personalization',
    text: [
      'Make your Surfer Buddy more personal. Pick images to your saved Pages and Collections.',
      'Here is a short instructional video on how make Picker more personal or read about it below.',
    ],
    hints: personalizationList,
    bgImage: bgGradient,
    iframeId: 'NtU6BsW8Z-s',
  },
];

export const workspaceAccordionList = [
  {
    title: 'Collections',
    text: [
      'You can choose to display them as most recently worked with or in alphabetical order. To the right you can see a small plus button, click the plus to create a new collection. The image you see is the default collection image and this will change when you make your first Pick or select a Collection image.Below that you see the collection name and how many pages are in that collection.',
    ],
  },
  {
    title: 'Sharing - Live Collection',
    text: [
      'By flipping the switch in the bottom left of a collection card you activate “Live collection”. This lets that specific collection be shared in real time with whomever you want. When you add a new note or make a change to your collection - the people you have shared it with will se your changes instantly.',
      'Press the share button next to the switch and select how you would like to share your Live Collection. If you ever change your mind and want to stop your collection from being live just flip the switch back to turn sharing off.',
    ],
  },
  {
    title: 'Extract key info',
    text: [
      'This function compiles all your sources, highlights and notes from an entire Collection and packages it into a PDF. This is a time saver for when you are working with online information for work or school and you want quick access only to the information you already have processed.',
    ],
  },
  {
    title: 'Pages',
    text: [
      'By clicking on a collection you will see all the pages that are collected in it. Just like with the collection page you can view pages by most recent or in alphabetical order.Here you can also select to only see time freeze mode.The pictures are the page images.In the sources- circle you see the logo of the page which it derives from and the name of the page.Underneath that you have the quick view option.Just mouse over the icons to see what tags, sticky notes, and highlights you have on that specific page.In the three dot menu you can change the page title or delete the page.',
    ],
  },
  {
    title: 'Organize',
    text: [
      'With drag and drop you can move pages from one collection to another, or copy a page over so that it exists in both collections. Similarly if you want to copy a page over you option click on Mac and Alt click on PC. Next to the search bar you can select whether you want to work with page images or without.',
    ],
  },
  {
    title: 'Tags',
    text: [
      'Here you can use the Tags that you created with your Surfer Buddy to find pages with similar topics even though they may reside in different collections. Select the tag or tags, and see which web pages you have saved.',
    ],
  },
  {
    title: 'Live Collections shared with you',
    text: [
      'This is the home for the Live Collections people have shared with you. Similar to Collections and Pages you can view by most recent or alphabetically. The three dot menu also lets you update the title or delete the live collection if you no longer want it.',
    ],
  },
  {
    title: 'AI chat',
    text: [
      'Quick and easy access to your AI - chat also from here. It is integrated in the same way as in your Surfer Buddy and Mobile app.and works in the same way.Once you typed in your question, just click “send”.',
      'To save an answer, select or create a new collection at the bottom of the window. Then click “Save answer” at the bottom of the answer.Then continue to chat or press “Start a new chat”.',
    ],
  },
];

export const featuresSections = [
  {
    title: 'The Surfer Buddy',
    subtitle: 'a great productivity companion',
    description: [
      'Picker makes it easy to quickly collect and work with information on pages across the web.',
    ],
    img: featurePicker,
  },
  {
    title: 'A personal workspace',
    subtitle: 'one place for everything that really matters',
    description: [
      'Create and organize your Collections with drag & drop.',
      'Real time sharing with Live Collections.',
    ],
    bgImg: wave3,
    img: workspace,
    bgPosition: '0 230px',
  },
  {
    title: 'Your Pick = Page card',
    subtitle: 'all key info at your finger tips',
    description: [
      'Each of your Picks is presented as a Page card within a collection with all key info quickly visible.',
    ],
    img: featureCard,
    bgPosition: '0 250px',
  },
  {
    title: 'Picker mobile app',
    subtitle: 'pick things that interests you on the go',
    description: [
      'AI chat, add notes, tag, create collections and save to your personal workspace on mypicker.net',
    ],
    img: featureMobile,
    bgPosition: '0 200px',
  },
  {
    title: 'AI chat made easy',
    subtitle: 'quick access to answers',
    description: [
      'Conviniently integrated in the Surfer Buddy, your Personal workspace on mypicker.net and in Picker mobile apps.',
    ],
    bgImg: wave4,
    img: featureChat,
    bgPosition: '0 270px',
  },
  {
    title: 'Live collection',
    subtitle: 'real time sharing',
    description: [
      'Share an entire Collection with all Notes, Highlights and Tags.',
    ],
    img: featureCollection,
  },
  {
    title: 'Time freeze',
    subtitle: 'freezes a webpage in time',
    description: [
      "In today's constant stream of updates, changes and deletions, being able to save a page frozen in time can be invaluable.",
    ],
    img: featureFreeze,
    bgPosition: '0 200px',
  },
  {
    title: 'Extract Key Info',
    subtitle: 'turn a Collection into a report',
    description: [
      'Compile all your Pages, Notes, Highlights and sources into a pdf.',
    ],
    bgImg: wave2,
    img: featureReport,
  },
];

export const whatIncludedList = [
  {
    title: 'Surfer Buddy',
    subtitle: 'Tool-kit (on/off)',
  },
  {
    title: 'Live Collections',
    subtitle: 'Real time sharing',
  },
  {
    title: 'AI as an integrated Tool',
    subtitle: 'Surfer Buddy, workspace, mobile',
  },
  {
    title: 'Save AI answers',
    subtitle: 'direct to collections',
  },
  {
    title: 'Extract Key Info',
    subtitle: 'from Collections',
  },
  {
    title: 'Highlighters',
  },
  {
    title: 'Floating Sticky Notes',
  },
  {
    title: 'Readability mode',
  },
  {
    title: 'Time-Freeze - Save',
    subtitle: 'webpages “frozen” in time',
  },
  {
    title: 'Personal workspace',
  },
  {
    title: 'Mobile App IOS/Android',
  },
];

export const headerText = (remainingTime) => {
  return {
    default: {
      title: 'Upgrade now',
      subtitle: `You are on a trial plan, that ends in ${remainingTime} days`,
    },
    trialOver: {
      title: 'Your trial period has ended, please select a plan',
      subtitle:
        'We accept all major bank and credit cards. Cancel at any time.',
    },
  };
};
