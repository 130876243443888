import { renderToStaticMarkup } from 'react-dom/server';
import ReactMarkdown from 'react-markdown';
import { Readability } from '@mozilla/readability';
import ArticleIcon from '@mui/icons-material/Article';
import CodeIcon from '@mui/icons-material/Code';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DOMPurify from 'dompurify';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';
import remarkGfm from 'remark-gfm';

const storage = getStorage();

export const getDataUrlFromFile = (file) =>
  new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });

export const getBlobFromDataUrl = (dataUrl) => {
  try {
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const bytesArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      bytesArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  } catch (e) {
    console.warn(e);
  }
};

export const uploadDataUrlFile = async (dataUrl, path, fileName, token) => {
  try {
    const file = getBlobFromDataUrl(dataUrl);

    const result = await fetch(
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o?name=${path}/${fileName}`,
      {
        body: file,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    try {
      const res = await result.json();
      return res;
    } catch (error) {
      throw new Error(`Error while parsing as JSON: ${error}`);
    }
  } catch (error) {
    return { status: 'error', code: error.code };
  }
};

export const deleteFilesFromFolder = async (path) => {
  const folderRef = ref(storage, path);

  const fileList = await listAll(folderRef);
  const promises = [];

  for (let item of fileList.items) {
    promises.push(deleteObject(item));
  }

  const result = await Promise.all(promises);
  return result;
};

export const deleteScreenshotImg = async (
  uuid,
  collectionId,
  screenshotName
) => {
  const folderRef = ref(
    storage,
    `users/${uuid}/collections/${collectionId}/screenshots/${screenshotName}`
  );

  if (!folderRef) return;

  const result = await deleteObject(folderRef);

  return result;
};

export const getImageSrc = (mapImg, title) => {
  const result = title ? title.charAt(0).toUpperCase() : '?';
  let img;
  mapImg[result] ? (img = mapImg[result]) : (img = mapImg['UFO']);
  return img;
};

export const isImgUrl = (url) => {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
};

export const toOneForm = (text, offlineText) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  const offlineDoc = parser.parseFromString(offlineText, 'text/html');

  const offlineContent = offlineDoc.body;

  const article = new Readability(doc, {
    keepClasses: true,
    serializer: (elem) => elem,
  }).parse();

  const offlineImgs = {};

  // Removing the onerror attribute for saved pages
  offlineContent.querySelectorAll('img').forEach((img) => {
    const src = new URL(img.src);
    img.removeAttribute('onerror');
    offlineImgs[src.pathname] = img;
  });

  // Adding the same src as the saved pages and removing the srsset attribute.
  article.content.querySelectorAll('img').forEach((img) => {
    const src = new URL(img.src);
    if (offlineImgs[src.pathname]) {
      img.src = offlineImgs[src.pathname].src;
      img.removeAttribute('srcset');
    }
  });

  const textContent = DOMPurify.sanitize(article && article.content);

  return { offlineContent: offlineContent.innerHTML, textContent };
};

export const markdownToHtml = (markdownText) => {
  return renderToStaticMarkup(
    <ReactMarkdown children={markdownText} remarkPlugins={[remarkGfm]} />,
    document.body
  );
};

export const formatTimestamp = (timestamp) => {
  if (!timestamp) return '';

  const date = timestamp.toDate();
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const getFileIcon = (fileName) => {
  if (!fileName) return <InsertDriveFileIcon />;
  const fileExtension = fileName.split('.').pop()?.toLowerCase();

  switch (fileExtension) {
    case 'pdf':
      return <PictureAsPdfIcon />;
    case 'doc':
    case 'docx':
      return <DescriptionIcon />;
    case 'ppt':
    case 'pptx':
      return <SlideshowIcon />;
    case 'md':
    case 'html':
      return <ArticleIcon />;
    case 'json':
      return <CodeIcon />;
    case 'txt':
      return <TextSnippetIcon />;
    default:
      return <InsertDriveFileIcon />;
  }
};
