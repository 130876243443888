import React, { lazy } from 'react';
import { PATHS } from 'AppPaths';
import { Route, Switch } from 'util/router';

import WithSuspense from './components/WithSuspense';

const Main = lazy(() => import('pages/main/Main'));
const Company = lazy(() => import('pages/company/Company'));
const Contacts = lazy(() => import('pages/contacts/Contacts'));
const GetYourSurferBuddy = lazy(() =>
  import('pages/getYourSurferBuddy/GetYourSurferBuddy')
);
const GetMobileApp = lazy(() => import('pages/getMobileApp/GetMobileApp'));
const Download = lazy(() => import('pages/download/Download'));
const PersonalWorkspace = lazy(() =>
  import('pages/personalWorkspace/PersonalWorkspace')
);
const Recipes = lazy(() => import('pages/recipes/Recipes'));
const Referral = lazy(() => import('pages/referral/Referral'));
const Features = lazy(() => import('pages/features/Features'));
const Support = lazy(() => import('pages/support/Support'));
const Pricing = lazy(() => import('pages/pricingNew/Pricing'));
const Paywall = lazy(() => import('pages/paywall/Paywall'));
const CheckoutCancel = lazy(() => import('pages/checkout/CheckoutCancel'));
const Bookmarks = lazy(() => import('pages/bookmarks/Bookmarks'));
const Collections = lazy(() => import('pages/collections/Collections'));
const Collection = lazy(() => import('pages/collection/Collection'));
const OfflinePage = lazy(() => import('pages/offlinePage/OfflinePage'));
const OpenSource = lazy(() => import('pages/openSource/OpenSource'));
const SharedCollection = lazy(() =>
  import('pages/sharedCollection/SharedCollection')
);
const Tags = lazy(() => import('pages/tags/Tags'));
const TryForFree = lazy(() => import('pages/tryForFree/TryForFree'));
const Organize = lazy(() => import('pages/organize/Organize'));
const AuthPage = lazy(() => import('pages/auth/AuthPage'));
const Settings = lazy(() => import('pages/settings/Settings'));
const TermsAndPrivacy = lazy(() =>
  import('pages/termsAndPrivacy/TermsAndPrivacy')
);
const CookiePolicy = lazy(() => import('pages/cookiePolicy/CookiePolicy'));
const FirebaseAction = lazy(() => import('pages/firebase-action'));
const NotFound = lazy(() => import('pages/notFound/NotFound'));

const Documents = lazy(() => import('pages/documents/Documents'));
const EnterpriseSignup = lazy(() =>
  import('pages/enterpriseSignup/EnterpriseSignup')
);
const EnterpriseSignupForm = lazy(() =>
  import('pages/enterpriseSignup/EnterpriseSignupForm')
);

const routesList = [
  { exact: true, path: '/', component: (props) => WithSuspense(Main, props) },
  {
    exact: true,
    path: PATHS.COMPANY,
    component: (props) => WithSuspense(Company, props),
  },
  {
    exact: true,
    path: PATHS.CONTACTS,
    component: (props) => WithSuspense(Contacts, props),
  },
  {
    exact: true,
    path: PATHS.GET_YOUR_SURFER_BUDDY,
    component: (props) => WithSuspense(GetYourSurferBuddy, props),
  },
  {
    exact: true,
    path: PATHS.GET_MOBILE_APP,
    component: (props) => WithSuspense(GetMobileApp, props),
  },
  {
    exact: true,
    path: PATHS.DOWNLOAD,
    component: (props) => WithSuspense(Download, props),
  },
  {
    exact: true,
    path: PATHS.PERSONAL_WORKSPACE,
    component: (props) => WithSuspense(PersonalWorkspace, props),
  },
  {
    exact: true,
    path: PATHS.FEATURES,
    component: (props) => WithSuspense(Features, props),
  },
  {
    exact: true,
    path: PATHS.SUPPORT,
    component: (props) => WithSuspense(Support, props),
  },
  {
    exact: true,
    path: PATHS.PRICING,
    component: (props) => WithSuspense(Pricing, props),
  },
  {
    exact: true,
    path: PATHS.PAYWALL,
    component: (props) => WithSuspense(Paywall, props),
  },
  {
    exact: true,
    path: PATHS.RECIPES,
    component: (props) => WithSuspense(Recipes, props),
  },
  {
    exact: true,
    path: PATHS.REFERRAL,
    component: (props) => WithSuspense(Referral, props),
  },
  {
    exact: true,
    path: PATHS.CHECKOUT_CANCEL,
    component: (props) => WithSuspense(CheckoutCancel, props),
  },
  {
    exact: true,
    path: PATHS.BOOKMARKS,
    component: (props) => WithSuspense(Bookmarks, props),
  },
  {
    exact: true,
    path: PATHS.COLLECTIONS,
    component: (props) => WithSuspense(Collections, props),
  },
  {
    exact: true,
    path: PATHS.COLLECTION_TEMPLATE,
    component: (props) => WithSuspense(Collection, props),
  },
  {
    exact: true,
    path: PATHS.COLLECTION_PAGE_TEMPLATE,
    component: (props) => WithSuspense(OfflinePage, props),
  },
  {
    exact: true,
    path: PATHS.CREDITS,
    component: (props) => WithSuspense(OpenSource, props),
  },
  {
    exact: true,
    path: PATHS.SHARED_TEMPLATE,
    component: (props) => WithSuspense(SharedCollection, props),
  },
  {
    exact: true,
    path: PATHS.TAGS,
    component: (props) => WithSuspense(Tags, props),
  },
  {
    exact: true,
    path: PATHS.TRYFORFREE,
    component: (props) => WithSuspense(TryForFree, props),
  },
  {
    exact: true,
    path: PATHS.ORGANIZE,
    component: (props) => WithSuspense(Organize, props),
  },
  {
    exact: true,
    path: PATHS.AUTH_TEMPLATE,
    component: (props) => WithSuspense(AuthPage, props),
  },
  {
    exact: true,
    path: PATHS.SETTINGS_TEMPLATE,
    component: (props) => WithSuspense(Settings, props),
  },
  {
    exact: true,
    path: PATHS.TERMS_AND_PRIVACY,
    component: (props) => WithSuspense(TermsAndPrivacy, props),
  },
  {
    exact: true,
    path: PATHS.COOKIE_POLICY,
    component: (props) => WithSuspense(CookiePolicy, props),
  },
  {
    exact: true,
    path: PATHS.FIREBASE_ACTION,
    component: (props) => WithSuspense(FirebaseAction, props),
  },
  {
    exact: true,
    path: PATHS.DOCUMENTS_TEMPLATE,
    component: (props) => WithSuspense(Documents, props),
  },
  {
    exact: true,
    path: PATHS.PRICE_LICENSE,
    component: (props) => WithSuspense(EnterpriseSignup, props),
  },
  {
    exact: true,
    path: PATHS.ENTERPRISE_SIGNUP,
    component: (props) => WithSuspense(EnterpriseSignupForm, props),
  },
  { component: (props) => WithSuspense(NotFound, props) },
];

const Routes = () => {
  return (
    <Switch>
      {routesList.map((el, index) => (
        <Route
          exact={el.exact}
          key={index}
          path={el.path}
          component={el.component}
        />
      ))}
    </Switch>
  );
};

export default Routes;
