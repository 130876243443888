import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useAuth } from 'util/auth';
import {
  addAiPage,
  createCollectionTJ,
  useCollectionsByOwnerTJ,
} from 'util/db';
import { markdownToHtml } from 'util/files';
import { getBrainEnabledCollections } from 'util/firebasefunctions';

import * as ST from './styles';

import CustomSwitch from 'ui/CustomSwitch';

import questionIcon from 'assets/icons/Qmark_B_bigger_white.svg';

const selectStyles = {
  container: (base) => ({
    ...base,
    '& div': {
      overflowX: 'clip',
      textOverflow: 'ellipsis',
      borderRadius: '10px',
    },
  }),
};

const ChatBotWindow = ({
  messages,
  isFullScreen,
  setFullScreen,
  handleSendRequest,
  scrollBoxRef,
  setIsError,
  setAlertMessage,
  setMessages,
  isLoading,
  currentUserMessage,
  setCurrentUserMessage,
  handleExtend,
  isExtended,
  setIsDraggerDisabled,
  setCollectionId,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null);
  const [isPendingSave, setSavePending] = useState(false);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(false);
  const [isBrainCollectionsLoading, setIsBrainCollectionsLoading] =
    useState(false);
  const [brainCollectionsActive, setBrainCollectionsActive] = useState(false);

  const [brainOptions, setBrainOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [selectCollection, setSelectCollection] = useState('');

  const auth = useAuth();

  const { data: items, status: itemsStatus } = useCollectionsByOwnerTJ(
    auth.user?.uid
  );

  const handleSaveClick = (i) => {
    if (!selectCollection) {
      setIsError(true);
      setAlertMessage('Please select a collection first');
    } else {
      setCurrentMessageIndex(i);
      setSavePending(true);
      try {
        addAiPage({
          owner: auth.user.uid,
          urlTitle: messages[i - 1].content,
          text: messages[i].content,
          collection: selectCollection.value,
          image: `${process.env.PUBLIC_URL}/images/ai.png`,
        }).then(() => {
          const messagesCopy = [...messages];
          messagesCopy[i].saved = true;
          setMessages(messagesCopy);
          setSavePending(false);
        });
      } catch (e) {
        setIsError(true);
        setAlertMessage('Something went wrong');
        console.warn(e);
      }
    }
  };

  const handleClear = () => {
    setMessages([]);
    setCurrentUserMessage('');
    setSelectCollection('');
    setCollectionId('');
  };

  const handleChange = (newValue) => {
    setSelectCollection(newValue);
    if (brainCollectionsActive) {
      setCollectionId(newValue?.value || '');
    } else {
      setCollectionId('');
    }
  };

  const handleCreate = (inputValue) => {
    setIsCollectionsLoading(true);
    const shouldSetValue = inputValue.length <= 50;
    if (shouldSetValue) {
      createCollectionTJ({
        title: inputValue,
        owner: auth.user.uid,
        shared: false,
      })
        .then((res) => {
          const newCollection = {
            label: inputValue,
            value: res.id,
            shared: false,
          };
          setSelectCollection(newCollection);
          if (brainCollectionsActive) {
            setCollectionId(res.id);
          } else {
            setCollectionId('');
          }
        })
        .finally(() => {
          setIsCollectionsLoading(false);
        });
    } else {
      alert('Collection Title is too long (max 50 characters)');
      setIsCollectionsLoading(false);
    }
  };

  const fetchBrainCollections = async (includeShared) => {
    setIsBrainCollectionsLoading(true);

    try {
      const brainEnabledCollections = await getBrainEnabledCollections({
        includeSharedCollections: includeShared,
      });

      if (brainEnabledCollections.data) {
        const brainData = brainEnabledCollections.data.map((item) => ({
          label: item.title,
          value: item.id,
          shared: item.shared,
        }));

        setBrainOptions(brainData);
      } else {
        setBrainOptions([]);
      }
    } catch (error) {
      setAlertMessage('Failed to get brain enabled collections');

      console.warn(error);
    } finally {
      setIsBrainCollectionsLoading(false);
    }
  };

  const handleBrainSwitchChange = async (event) => {
    const isChecked = event.target.checked;
    setBrainCollectionsActive(isChecked);
    setSelectCollection('');
    setCollectionId('');
    await fetchBrainCollections(isChecked);
  };

  useEffect(() => {
    if (itemsStatus !== 'loading' && items) {
      let data = [];

      items.forEach((item) => {
        data.push({ label: item.title, value: item.id, shared: item.shared });
      });

      setOptions(data);
      setIsCollectionsLoading(false);
    } else {
      setIsCollectionsLoading(true);
    }
  }, [items, itemsStatus]);

  return (
    <ST.StyledChatBox mx="auto" p={0.3} onDoubleClick={handleExtend}>
      <Box display="flex" pb={1} alignItems="center">
        <ST.StyledIcon src={questionIcon} alt="question" />
        <Typography ml={1}>AI chat</Typography>
        <ST.StyledButton
          startIcon={
            isFullScreen ? <CloseFullscreenIcon /> : <FullscreenIcon />
          }
          onClick={() => setFullScreen(!isFullScreen)}
        />
      </Box>
      <ST.DividedLine />
      <Box
        display="flex"
        flexDirection="column"
        mt={1}
        position="relative"
        flexGrow={2}
        height={isFullScreen ? '200px' : 'unset'}
      >
        <ST.ChatForm onSubmit={handleSendRequest} autoComplete="off">
          <ST.ChatFieldBox
            isActive={messages.length === 0}
            isFullScreen={isFullScreen}
          >
            {messages.length === 0
              ? 'This is the answer area Ask your question below'
              : messages.map((message, i) =>
                  message.role === 'user' ? (
                    <ST.MessageBox key={i} ref={scrollBoxRef} isUser>
                      {message.content}
                    </ST.MessageBox>
                  ) : (
                    <ST.ContentBox key={i} isFullScreen={isFullScreen}>
                      <ST.MessageBox
                        ref={scrollBoxRef}
                        isUser={false}
                        dangerouslySetInnerHTML={{
                          __html: markdownToHtml(message.content),
                        }}
                      />
                      {!message.saved &&
                        (isPendingSave && currentMessageIndex === i ? (
                          <CircularProgress size={24} color="secondary" />
                        ) : (
                          <ST.StyledSaveButton
                            onClick={() => handleSaveClick(i)}
                          >
                            <Typography
                              fontSize={12}
                              onDoubleClick={(e) => e.stopPropagation()}
                            >
                              Save answer
                            </Typography>
                            <DownloadIcon />
                          </ST.StyledSaveButton>
                        ))}
                    </ST.ContentBox>
                  )
                )}
          </ST.ChatFieldBox>
          {isLoading && <ST.LoadingProgress />}
          <ST.StyledQuestionField
            disabled={isLoading}
            placeholder="Type your question..."
            value={currentUserMessage}
            onChange={(e) => {
              setCurrentUserMessage(e.target.value);
            }}
            onDoubleClick={(e) => e.stopPropagation()}
            onMouseEnter={() => setIsDraggerDisabled(true)}
            onMouseLeave={() => setIsDraggerDisabled(false)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ST.StyledButton
                    startIcon={
                      <ST.StyledSendIcon onClick={handleSendRequest} />
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
        </ST.ChatForm>
      </Box>
      <ST.StyledActionBox isFullScreen={isFullScreen}>
        <ST.StyledResetButton
          variant="outlined"
          isFullScreen={isFullScreen}
          onClick={handleClear}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          Start new Chat
        </ST.StyledResetButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            onDoubleClick={(e) => e.stopPropagation()}
            width={isFullScreen ? '170px' : '160px'}
            maxWidth="218px"
            color="black"
            textOverflow="ellipsis"
            sx={{ textWrap: 'nowrap' }}
          >
            <CreatableSelect
              styles={selectStyles}
              isDisabled={isCollectionsLoading}
              isLoading={isCollectionsLoading}
              onChange={handleChange}
              onCreateOption={handleCreate}
              options={brainCollectionsActive ? brainOptions : options}
              value={selectCollection}
              placeholder="Select collection"
              menuPlacement={isExtended ? 'top' : 'bottom'}
            />
          </Box>
          <CustomSwitch
            isChecked={brainCollectionsActive}
            onChange={handleBrainSwitchChange}
            isDisabled={isBrainCollectionsLoading}
          />
          <Box>
            {isBrainCollectionsLoading && (
              <CircularProgress
                size={25}
                sx={{ color: '#527da3', position: 'relative', top: '3px' }}
              />
            )}
          </Box>
        </Box>
      </ST.StyledActionBox>
    </ST.StyledChatBox>
  );
};

export default ChatBotWindow;
