import { Box, CircularProgress } from '@mui/material';

import * as ST from './style';

const CustomSwitch = ({ isChecked, onChange, isDisabled, isLoading }) => {
  return (
    <Box>
      <ST.IOSSwitch
        label="iOS style"
        sx={{ m: 1 }}
        checked={isChecked}
        onChange={onChange}
        disabled={isDisabled}
      />
      {isLoading && (
        <CircularProgress
          size={25}
          sx={{ color: '#cc3300', position: 'relative', top: '7px' }}
        />
      )}
    </Box>
  );
};

export default CustomSwitch;
