import React, { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button, Link as LinkMui, Typography } from '@mui/material';
import { PATHS } from 'AppPaths';
import { getAuth } from 'firebase/auth';
import { useAtom } from 'jotai';
import { isDrawerOpenAtom } from 'store';
import { firebaseApp } from 'util/firebase';
import { Link, useRouter } from 'util/router';

import DividingLine from '../Auth/DividingLine';
import AuthForm from '../AuthForm';
import AuthSocial from '../AuthSocial';
import FormAlertMessage from './FormAlertMessage';

const auth = getAuth(firebaseApp);

const Auth = (props) => {
  const router = useRouter();

  const [, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);

  const [formAlert, setFormAlert] = useState(null);
  const [isCreateClicked, setIsCreateClicked] = useState(false);

  const handleAuth = () => {
    router.push(props.afterAuthPath);
    setIsDrawerOpen(true);
  };

  const redirectPaths = {
    signup: PATHS.PRICE_LICENSE,
    signin: PATHS.AUTH('signin'),
  };

  return (
    <>
      {formAlert && (
        <FormAlertMessage
          formAlert={formAlert}
          user={auth.user}
          isResendEmail
        />
      )}

      {props.isModal &&
        ['signup', 'signin'].includes(props.type) &&
        props.providers &&
        props.providers.length &&
        (!isCreateClicked ? (
          <>
            <AuthSocial
              buttonAction={props.buttonAction}
              providers={props.providers}
              handleClose={props.handleClose}
              showLastUsed={true}
              onAuth={handleAuth}
              onError={(message) => {
                setFormAlert({ type: 'error', message: message });
              }}
            />

            <DividingLine />
            <Button
              variant="outlined"
              color="primary"
              size="large"
              type="button"
              fullWidth
              to={redirectPaths[props.type]}
              onClick={props.handleClose}
              component={Link}
              startIcon={<MailOutlineIcon />}
              sx={{ fontWeight: 'bold', mb: 2 }}
            >
              <span>Continue with Email</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              fullWidth={true}
              onClick={() => setIsCreateClicked(true)}
            >
              <span>{props.buttonAction}</span>
            </Button>
          </>
        ) : (
          <AuthForm
            type={props.type}
            buttonAction={props.buttonAction}
            isForgotPasswordLink={props.isForgotPasswordLink}
            onAuth={handleAuth}
            onFormAlert={setFormAlert}
            handleClose={props.handleClose}
          />
        ))}

      {!props.isModal && (
        <>
          <AuthForm
            type={props.type}
            buttonAction={props.buttonAction}
            isForgotPasswordLink={props.isForgotPasswordLink}
            onAuth={handleAuth}
            onFormAlert={setFormAlert}
            handleClose={props.handleClose}
          />
          <DividingLine />
          <AuthSocial
            buttonAction={props.buttonAction}
            providers={props.providers}
            handleClose={props.handleClose}
            showLastUsed={true}
            onAuth={handleAuth}
            onError={(message) => {
              setFormAlert({ type: 'error', message: message });
            }}
          />
        </>
      )}

      {props.type === 'signin' && (
        <Typography textAlign="center" pt={2}>
          I have forgot my{' '}
          <LinkMui
            component={Link}
            to={PATHS.AUTH('forgotpass')}
            underline="none"
            onClick={() => {
              props.isModal && props.handleClose();
            }}
          >
            Password
          </LinkMui>
        </Typography>
      )}
    </>
  );
};

export default Auth;
