import React, { useState } from 'react';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PATHS } from 'AppPaths';
import { Link } from 'util/router.js';
import { useDarkMode } from 'util/theme.js';

import ChatBot from 'components/ChatBot';
import DrawerHeader from 'components/DrawerHeader';
import ShareModal from 'components/ShareModal';

import { StyledDrawer } from './styles';

import CustomButton from 'ui/CustomButton';

import { ReactComponent as LiveBookmark } from 'assets/icons/Live_Collection.svg';
import OrganizeBlack from 'assets/icons/Organize01';
import OrganizeWhite from 'assets/icons/Organize01W';

const sharedUrl = new URL(process.env.PUBLIC_URL || 'https://mypicker.net/');

const MyDrawer = ({ drawerWidth, open, ToggleDrawer }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);

  const theme = useTheme();

  const darkMode = useDarkMode();

  return (
    <StyledDrawer
      variant="persistent"
      anchor="left"
      open={open}
      drawerWidth={drawerWidth}
    >
      <DrawerHeader>
        <IconButton onClick={ToggleDrawer}>
          {theme.direction === 'ltr' ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItem button={true} component={Link} to={PATHS.COLLECTIONS}>
          <IconButton color="inherit" size="large">
            <DashboardOutlinedIcon />
          </IconButton>
          <ListItemText>Collections</ListItemText>
        </ListItem>
        <ListItem button={true} component={Link} to={PATHS.ORGANIZE}>
          <IconButton color="inherit" size="large">
            {darkMode.value ? (
              <OrganizeWhite width="20px" />
            ) : (
              <OrganizeBlack width="20px" />
            )}
          </IconButton>
          <ListItemText>Organize</ListItemText>
        </ListItem>
        <ListItem button={true} component={Link} to={PATHS.TAGS}>
          <IconButton color="inherit" size="large">
            <LocalOfferOutlinedIcon />
          </IconButton>
          <ListItemText>Tags</ListItemText>
        </ListItem>
        <ListItem button={true} component={Link} to={PATHS.BOOKMARKS}>
          <IconButton color="inherit" size="large">
            {darkMode.value ? (
              <LiveBookmark width="20px" fill="#f2f2f2" />
            ) : (
              <LiveBookmark width="20px" fill="#000" />
            )}
          </IconButton>
          <ListItemText>Live Collections</ListItemText>
        </ListItem>
      </List>
      <ChatBot isFullScreen={isFullScreen} setFullScreen={setFullScreen} />
      <CustomButton
        sx={{ borderRadius: '10px' }}
        variant="contained"
        width="96%"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Share Picker!
      </CustomButton>
      {isModalOpen && (
        <ShareModal
          onDone={() => setIsModalOpen(null)}
          title="Share Picker!"
          sharedUrl={sharedUrl}
        />
      )}
    </StyledDrawer>
  );
};
export default MyDrawer;
